<template>
<div>
  <footer class="footer-area mg-top-120" :style="backgroundImageInlineStyle">
    <div class="footer-top pd-top-120 padding-bottom-40">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-9 col-lg-11">
            <div class="footer-widget widget text-center">
              <div class="footer_top_widget">
                <router-link class="footer-logo" to="/"><img src="img/logo.png" alt="footer logo"></router-link>
                <p>Levering businesses to smarter operations and a sharper reach.</p>
              </div>
            </div>
            <div class="footer-widget widget widget_nav_menu text-center">
              <ul>
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/services">Services</router-link></li>
                <li><router-link to="/about">About us</router-link></li>
                <li><router-link to="/contact">Contact us</router-link></li>
              </ul>
            </div>
            <div class="copyright-inner">
              <div class="row custom-gutters-16">
                <div class="col-lg-7">
                  <div class="copyright-text">
                    &copy; <a href="https://bio.link/leviersoft" target="_blank">LevierSoft Solutions</a> 2023 All rights reserved.
                  </div>
                </div>
                <div class="col-lg-5">
                  <ul class="social-icon text-right">
                    <li>
                      <a class="facebook" href="https://www.facebook.com/profile.php?id=100085895757948" target="_blank"><i class="fa fa-facebook  "></i></a>
                    </li>
                    <li>
                      <a class="twitter" href="https://twitter.com/leviersoft" target="_blank"><i class="fa fa-twitter  "></i></a>
                    </li>
                    <li>
                      <a class="linkedin" href="https://www.linkedin.com/company/leviersoft-solutions/" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </li>
                    <li>
                      <a class="pinterest" href="https://www.instagram.com/leviersoft/" target="_blank"><i class="fa fa-instagram"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>
</template>

<script>
export default {
  name: "FooterComponent",
  computed: {
    backgroundImageInlineStyle() {
      return `background-image: url("img/bg/footer.png");`;
    }
  }
}
</script>

<style scoped>

</style>