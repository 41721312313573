import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import(/* webpackChunkName: "services" */ '../views/ServicesView.vue')
  },
  {
    path: '/web',
    name: 'web',
    component: () => import(/* webpackChunkName: "web" */ '../views/WebView.vue')
  },
  {
    path: '/bs',
    name: 'bs',
    component: () => import(/* webpackChunkName: "bs" */ '../views/BsView.vue')
  },
  {
    path: '/dm',
    name: 'dm',
    component: () => import(/* webpackChunkName: "dm" */ '../views/DmView.vue')
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import(/* webpackChunkName: "bs" */ '../views/AnalyticsView.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
