<template>

  <nav-component/>
  <router-view/>
  <footer-component />
  <backtotop-component />

</template>
<script>
import NavComponent from "@/components/NavComponent";
import FooterComponent from "@/components/FooterComponent";
import BacktotopComponent from "@/components/BacktotopComponent";
export default {
  components: {BacktotopComponent, FooterComponent, NavComponent}
}
</script>