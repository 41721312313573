<template>
  <div class="back-to-top">
    <span class="back-top"><i class="fa fa-angle-up"></i></span>
  </div>
</template>

<script>
export default {
  name: "BacktotopComponent"
}
</script>

<style scoped>

</style>