<template>
  <div class="home">
    <div class="header-area header-bg" :style="backgroundImageInlineStyle">
      <div class="container">
        <div class="banner-slider banner-slider-one">
          <div class="banner-slider-item">
            <div class="row">
              <div class="col-xl-5 col-lg-6 col-md-6 offset-xl-1">
                <div class="header-inner-details">
                  <div class="header-inner">
                    <h1 class="title s-animate-1">
                      Efficient <span> solutions</span><br />that streamline
                      operability.
                    </h1>
                    <p class="s-animate-2">
                      With the right software systems in place, <br />
                      your business can finally run on 'autopilot mode'.
                    </p>
                    <div class="btn-wrapper desktop-left padding-top-20">
                      <a href="#" class="btn btn-radius btn-green s-animate-3"
                        >Read More</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 hidden-sm">
                <div class="banner-thumb-wrap">
                  <div class="banner-thumb">
                    <img
                      class="header-inner-img"
                      src="img/banner/1.png"
                      alt="banner-img"
                    />
                    <img
                      class="banner-1-img2"
                      src="img/banner/2.png"
                      alt="banner-img"
                    />
                    <img
                      class="banner-1-img3"
                      src="img/banner/3.png"
                      alt="banner-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="banner-slider-item">
            <div class="row">
              <div class="col-xl-5 col-lg-6 col-md-6 offset-xl-1">
                <div class="header-inner-details">
                  <div class="header-inner">
                    <h1 class="title s-animate-1">
                      Crafting modern <span>software</span> for you
                    </h1>
                    <p class="s-animate-2">
                      We strive for an impressive finish, <br />
                      but most importantly, one that serves its purpose
                      glitch-free.
                    </p>
                    <div class="btn-wrapper desktop-left padding-top-20">
                      <a href="#" class="btn btn-radius btn-green s-animate-3"
                        >Read More</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 hidden-sm">
                <div class="banner-thumb-wrap">
                  <div class="banner-thumb">
                    <img
                      class="header-inner-img"
                      src="img/banner/01.png"
                      alt="banner-img"
                    />
                    <img
                      class="banner-1-img2"
                      src="img/banner/02.png"
                      alt="banner-img"
                    />
                    <img
                      class="banner-1-img3"
                      src="img/banner/03.png"
                      alt="banner-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="banner-slider-item">
            <div class="row">
              <div class="col-xl-5 col-lg-6 col-md-6 offset-xl-1">
                <div class="header-inner-details">
                  <div class="header-inner">
                    <h1 class="title s-animate-1">
                      Taking advantage of <span>digital</span> to brand and grow
                    </h1>
                    <p class="s-animate-2">
                      How people perceive your brand online has become
                      everything, <br />
                      this is exactly how we get you ahead.
                    </p>
                    <div class="btn-wrapper desktop-left padding-top-20">
                      <a href="#" class="btn btn-radius btn-green s-animate-3"
                        >Read More</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 hidden-sm">
                <div class="banner-thumb-wrap">
                  <div class="banner-thumb">
                    <img
                      class="header-inner-img"
                      src="img/banner/1.png"
                      alt="banner-img"
                    />
                    <img
                      class="banner-1-img2"
                      src="img/banner/2.png"
                      alt="banner-img"
                    />
                    <img
                      class="banner-1-img3"
                      src="img/banner/03.png"
                      alt="banner-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- service area start -->
    <div class="service-area">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-7 col-lg-10">
            <div class="section-title text-center margin-bottom-90">
              <h2
                class="title wow animated fadeInUp"
                data-wow-duration="0.6s"
                data-wow-delay="0.1s"
              >
                What <span>Services</span> do we provide?
              </h2>
              <p
                class="wow animated fadeInUp"
                data-wow-duration="0.6s"
                data-wow-delay="0.2s"
              >
                The hub for efficient software development and creative digital
                marketing.
              </p>
            </div>
          </div>
        </div>
        <div class="row custom-gutters-16">
          <div class="col-xl-3 col-md-6 col-sm-6">
            <router-link to="/web">
            
            <div
              class="single-service wow animated fadeInUp"
              data-wow-duration="0.4s"
              data-wow-delay="0.1s"
            >
              <img src="img/service/project.svg" alt="service" />
              <h6><a href="#">Web Development</a></h6>
              <p>
                We build websites and web systems that are tailored for your
                business interests, whether it's eye catching carousels or
                information systems.
              </p>
              <div class="read-more">
                <a href="#"><img src="img/service/arrow.png" alt="arrow" /></a>
              </div>
            </div>
            </router-link>
          </div>
          <div class="col-xl-3 col-md-6 col-sm-6">
            <router-link to="/bs">
            <div
              class="single-service wow animated fadeInUp"
              data-wow-duration="0.6s"
              data-wow-delay="0.2s"
            >
              <img src="img/service/hiring.svg" alt="service" />
              <h6><a href="#">Business Software</a></h6>
              <p>
                As your business expands, you need bespoke toolsets that
                increase efficiency in: sales, inventory, customer mangement,
                workflows etc.
              </p>
              <div class="read-more">
                <a href="#"><img src="img/service/arrow.png" alt="arrow" /></a>
              </div>
            </div>
            </router-link>
          </div>
          <div class="col-xl-3 col-md-6 col-sm-6">
            <router-link to="/dm">
            <div
              class="single-service wow animated fadeInUp"
              data-wow-duration="0.8s"
              data-wow-delay="0.3s"
            >
              <img src="img/service/5.svg" alt="service">
              <h6><a href="#">Digital Marketing</a></h6>
              <p>
                Our fully-fleshed team is experienced in capturing the attention
                of your target market with exquisite designs and a learned
                approach to digital advertising.
              </p>
              <div class="read-more">
                <a href="#"><img src="img/service/arrow.png" alt="arrow" /></a>
              </div>
            </div>
            </router-link>
          </div>
          <div class="col-xl-3 col-md-6 col-sm-6">
            <router-link to="analytics">
            <div
              class="single-service wow animated fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <img src="img/service/analytics.svg" alt="service" />
              <h6><a href="#">BI & Analytics</a></h6>
              <p>
                The assignment- collecting your online data, modelling it and
                providing solutions to retain customers and patch up the missing
                parts of your business.
              </p>
              <div class="read-more">
                <a href="#"><img src="img/service/arrow.png" alt="arrow" /></a>
              </div>
            </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- service area End -->

    <!-- soft-box area start -->
    <div
      class="sbs-what-LevierSoft Solutions pd-default-120 mg-top-105"
      :style="backgroundImageInlineStyle2"
    >
      <div class="container">
        <div class="row">
          <div
            class="col-lg-6 wow animated fadeInRight"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <img src="img/we-provide/5.png" alt="img" />
          </div>
          <div class="col-xl-5 col-lg-6 desktop-center-item">
            <div
              class="desktop-center-area wow animated fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div class="section-title style-two">
                <h2 class="title">
                  What is <span>LevierSoft Solutions?</span>
                </h2>
                <p>
                  LevierSoft Solutions is a Private Limited Company which
                  specializes in using technology to leverage business
                  operations. Its business constitutes two entities which are
                  software development and digital marketing. Founded in 2022,
                  its aim is to make businesses: <br />
                  i) operate more efficiently in their day to day activities
                  using technology.<br />
                  ii) scale their market reach and branding using digital
                  solutions<br />
                </p>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="riyaqas-check-list">
                    <img src="img/icons/check.svg" alt="check" />
                    <span>Modern and Agile</span>
                  </div>
                  <div class="riyaqas-check-list">
                    <img src="img/icons/check.svg" alt="check" />
                    <span>Smart implementations</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="riyaqas-check-list">
                    <img src="img/icons/check.svg" alt="check" />
                    <span>Reliable Partnerships</span>
                  </div>
                  <div class="riyaqas-check-list">
                    <img src="img/icons/check.svg" alt="check" />
                    <span>Growth Mindset</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- soft-box area End -->

    <div>
      <!-- soft-box area start -->
      <div class="sbs-provide-security pd-top-120">
        <div class="container">
          <div class="row justify-content-center">
            <div
              class="col-xl-6 col-lg-6 offset-xl-1 order-lg-12 wow animated fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div class="mask-bg-wrap mask-bg-img-3 mb-lg-0 mb-4">
                <div class="thumb">
                  <img src="img/we-provide/7.png" alt="video" />
                </div>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-6 order-lg-1 align-self-center wow animated fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div class="section-title style-two">
                <h2 class="title"><span>Clients</span> First</h2>
              </div>
              <div class="tab-content">
                <div class="tab-pane fade show active" id="d_tabs_1">
                  <div class="riyaqas-stab-content">
                    <p>
                      At LevierSoft, we prioritize pulling the weight so you do
                      not. As easy as it is to get carried away in 'check-box
                      ticking' and over indulging in creating, we strive to
                      carry your requirements from the beginning to the end of a
                      project to satisfy your end goal.
                    </p>
                    <p>
                      Our strength is in building long-lasting partnerships to
                      cheer on the sidelines as your business breaks through and
                      expands into a giant, well-oiled machine.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- soft-box area End -->

    <!-- soft-box area start -->
    <div
      class="leftside-image-area service-area sbs-business-tool pd-bottom-120 mg-top-120"
      :style="backgroundImageInlineStyle3"
    >
      <div class="container">
        <div class="row">
          <div
            class="col-lg-6 col-xl-7 wow animated fadeInRight"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <img src="img/business-tool/1.png" alt="img" />
          </div>
          <div class="col-xl-5 col-lg-6 desktop-center-item">
            <div
              class="desktop-center-area wow animated fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div class="section-title style-two">
                <h2 class="title">
                  Your
                  <span>smart<br /> </span>
                  business tools
                </h2>
                <p>
                  Save time, money and effort on redundant, mammoth tasks.
                  <br />
                  Switch over to the professional gear.
                </p>
              </div>
              <div class="row">
                <div class="col-lg-9">
                  <div class="riyaqas-check-list media">
                    <img
                      class="media-left"
                      src="img/business-tool/pencil.svg"
                      alt="check"
                    />
                    <span>Customer interaction automation.</span>
                  </div>
                  <div class="riyaqas-check-list media">
                    <img
                      class="media-left"
                      src="img/business-tool/search.svg"
                      alt="check"
                    />
                    <span>Data manipulation.</span>
                  </div>
                  <div class="riyaqas-check-list media mg-bottom-0-991">
                    <img
                      class="media-left"
                      src="img/business-tool/settings.svg"
                      alt="check"
                    />
                    <span>Strategic management.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- soft-box area End -->

    <!-- soft-box area start -->
    <div class="sbs-provide-security pd-top-120">
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-xl-6 col-lg-6 order-lg-12 wow animated fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <div class="img-with-video">
              <div class="img-wrap">
                <img src="img/we-provide/1.png" alt="video" />
                <div class="hover">
                  <a
                    href="https://www.youtube.com/watch?v=c7XEhXZ_rsk"
                    class="video-play-btn mfp-iframe"
                    ><img src="img/we-provide/3.svg" alt="video"
                  /></a>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-5 col-lg-6 order-lg-1 align-self-center wow animated fadeInRight"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <div class="section-title style-two">
              <h2 class="title">
                How we connect <br />
                you to <span>customers</span>
              </h2>
              <p>
                Our team is experienced in online branding, lead generation and
                resource allocation across the web, social media and e-mails.
              </p>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="riyaqas-check-list">
                  <img class="media-left" src="img/icons/9.svg" alt="check" />
                  <span>Unique designs</span>
                </div>
                <div class="riyaqas-check-list">
                  <img class="media-left" src="img/icons/10.svg" alt="check" />
                  <span>SEO</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="riyaqas-check-list">
                  <img class="media-left" src="img/icons/11.svg" alt="check" />
                  <span>Cross platform approach</span>
                </div>
                <div class="riyaqas-check-list">
                  <img class="media-left" src="img/icons/12.svg" alt="check" />
                  <span>Innovative analytics</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- soft-box area End -->

    <!-- testimonial area Start -->
    <div
      class="testimonial-section sbs-testimonial-section pd-top-105 pd-bottom-120"
      :style="backgroundImageInlineStyle4"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-6 pd-xl-5 order-lg-12 align-self-center">
            <div class="section-title style-two mb-0">
              <h2 class="title">Our<span>clients</span></h2>
              <p>
                We have worked with a number of companies and <br />
                small businesses, all having one thing in common:
                <br />the need to <b>leverage</b> their business using
                technology.
              </p>
            </div>
          </div>
          <div class="col-lg-6 order-lg-1">
            <div class="sbs-testimonial-slider">
              <div class="choose_slider">
                <div class="choose_slider_items">
                  <ul id="testimonial-slider">
                    <li class="current_item">
                      <div class="media">
                        <img
                          class="media-left"
                          src="img/client/4.png"
                          alt="client"
                        />
                        <div class="media-body">
                          <h6>Innocent Kashiri</h6>
                          <p class="designation">CEO at Innet Technologies</p>
                          <span>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                          </span>
                        </div>
                      </div>
                      <!-- <p>
                        Our support team will get assistance from AI-powered
                        suggestions, making it quicker than ever to handle
                        support requests.
                      </p> -->
                    </li>
                    <li class="current_item">
                      <div class="media">
                        <img
                          class="media-left"
                          src="img/client/5.png"
                          alt="client"
                        />
                        <div class="media-body">
                          <h6>Samantha Moyo</h6>
                          <p class="designation">COO at Limitlex Africa</p>
                          <span>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                          </span>
                        </div>
                      </div>
                  
                    </li>
                    <li class="current_item">
                      <div class="media">
                        <img
                          class="media-left"
                          src="img/client/4.png"
                          alt="client"
                        />
                        <div class="media-body">
                          <h6>Fredman Munyaradzi</h6>
                          <p class="designation">
                            Co-Founder at FK Ultimate Construction
                          </p>
                          <span>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                          </span>
                        </div>
                      </div>
               
                    </li>
                    <li class="current_item">
                      <div class="media">
                        <img
                          class="media-left"
                          src="img/client/4.png"
                          alt="client"
                        />
                        <div class="media-body">
                          <h6>Brian Zhou</h6>
                          <p class="designation">CEO at Heidelberg</p>
                          <span>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                          </span>
                        </div>
                      </div>
            
                    </li>
                    <li class="current_item">
                      <div class="media">
                        <img
                          class="media-left"
                          src="img/client/5.png"
                          alt="client"
                        />
                        <div class="media-body">
                          <h6>Mr IDK</h6>
                          <p class="designation">CMO at Setmac Mining</p>
                          <span>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                          </span>
                        </div>
                      </div>
            
                    </li>
                  </ul>
                </div>
              </div>
              <div class="sbs-arrowleft">
                <a id="btn_next" href="#"
                  ><i class="fa fa-long-arrow-left"></i
                ></a>
              </div>
              <div class="sbs-arrowright">
                <a id="btn_prev" href="#"
                  ><i class="fa fa-long-arrow-right"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    backgroundImageInlineStyle() {
      return `background-image: url("img/bg/banner-bg.png");`;
    },
    backgroundImageInlineStyle2() {
      return `background-image: url("img/bg/1h1.png");`;
    },
    backgroundImageInlineStyl3() {
      return `background-image: url("img/bg/1h2.png");`;
    },
    backgroundImageInlineStyle4() {
      return `background-image: url("img/bg/1h3.png");`;
    },
  },
};
</script>
