<template>
  <div>
    <nav class="navbar navbar-area navbar-expand-lg nav-style-01">
      <div class="container nav-container">
        <div class="responsive-mobile-menu">
          <div class="logo-wrapper mobile-logo">
            <a href="index.html" class="logo">
              <img src="img/logo.png" alt="logo">
            </a>
          </div>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#Riyaqas_main_menu"
                  aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggle-icon">
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                </span>
          </button>
        </div>
        <div class="collapse navbar-collapse" id="Riyaqas_main_menu">
          <div class="logo-wrapper desktop-logo">
            <a href="index.html" class="logo">
              <img src="img/logo.png" alt="logo">
            </a>
          </div>
          <ul class="navbar-nav">
            <li>
              <router-link to="/">Home</router-link>
            </li>

            <li>
              <router-link to="/services">Services</router-link>
            </li>

            <li>
              <router-link to="/about">About us</router-link>
            </li>

            <li>
              <router-link to="/contact">Contact us</router-link>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavComponent"
}
</script>

<style scoped>

</style>